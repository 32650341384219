/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { forwardRef, useCallback, useMemo } from "react";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import IconLabelButton from "components/molecules/IconLabelButton";
import FlexBox from "components/atoms/FlexBox";
import { isMacOs } from "react-device-detect";
import { useDownload } from "./hooks";
import SortIcon from "components/molecules/SortIcon";
import Icon from "components/atoms/Icon";
import { grayScale } from "components/styles";
import useSortTable, { FieldSortState, SortColumn } from "hooks/useSortTable";
import { getTargetWeekText } from "utility/formatUtil";
import ItemReportDomain from "domain/master/storeManagement/ItemReport";
import { FixedSizeList as List } from "react-window";

const DEFAULT_SORT_FIELD = {
  fieldPath: "itemOrder",
  sort: "asc",
  sortType: "number"
} as FieldSortState;

const Row: React.FC<{
  index: number;
  style: React.CSSProperties;
  data: ItemReportDomain[];
}> = ({ index, style, data }) => {
  const item = data[index];
  return (
    <tr key={index}>
      <td
        style={{ left: "0", zIndex: 95 }}
        className="text-center stickyStyle fristColSticky"
      >
        <span>
          <div className="text-ellipsis">{item.itemRank}</div>
        </span>
      </td>
      <td
        style={{ left: "103px", zIndex: 95 }}
        className="text-center stickyStyle "
      >
        <span>
          <div className="text-ellipsis">{item.itemOrder}</div>
        </span>
      </td>
      <td
        style={{ left: "206px", zIndex: 95 }}
        className="text-center stickyStyle "
      >
        <span>
          <div className="text-ellipsis">{item.menuCode}</div>
        </span>
      </td>
      <td
        style={{ left: "409px", zIndex: 95 }}
        className="text-center stickyStyle "
      >
        <span>
          <div className="text-ellipsis">{item.menuName}</div>
        </span>
      </td>
      <td className="text-right">
        <span>{item.unitPrice}</span>
      </td>
      <td className="text-right">
        <span>{item.cost}</span>
      </td>
      <td className="text-right">
        <span>{item.totalItem}</span>
      </td>
      <td className="text-right">
        <span>{item.totalPrice}</span>
      </td>
      <td className="text-right">
        <span>{item.compositionRatio}</span>
      </td>
      <td className="text-right">
        <span>{item.cumulativeRatio}</span>
      </td>
      <td className="text-right">
        <span>{item.totalCost}</span>
      </td>
      <td className="text-right">
        <span>{item.profit}</span>
      </td>
      <td className="text-center">
        <span>{item.largeDivisionName}</span>
      </td>
      <td className="text-center">
        <span>{item.smallDivisionName}</span>
      </td>
    </tr>
  );
};

const ItemCntOrderTable: React.FC<{
  setIsLoading: any;
  itemReport: { items: ItemReportDomain[]; total: ItemReportDomain };
  targetPeriodData: any;
  itemStartTime: any;
  itemEndTime: any;
  formik: any;
  orgCode: any;
  orgName: string;
  orgTreesOptions: any;
  roleScreen?: any;
}> = ({
  setIsLoading,
  itemReport,
  targetPeriodData,
  itemStartTime,
  itemEndTime,
  formik,
  orgCode,
  orgName,
  orgTreesOptions,
  roleScreen
}) => {
  // ------------------------------------------------------------------
  // ソート
  // ------------------------------------------------------------------
  /** ソート項目 */
  const sortColumns: Array<SortColumn> = useMemo(
    () => [
      {
        displayName: "ランク",
        sortField: "itemRank",
        sortType: "string",
        style: { top: "0", left: "0", zIndex: 99 },
        className: "text-center stickyStyle fristColSticky"
      },
      {
        displayName: "順位",
        sortField: "itemOrder",
        sortType: "number",
        style: { top: "0", left: "103px", zIndex: 99 },
        className: "text-center stickyStyle fristColSticky"
      },
      {
        displayName: "商品コード",
        sortField: "menuCode",
        sortType: "string",
        style: {
          top: "0",
          left: "206px",
          zIndex: 99,
          maxWidth: " 200px",
          width: "200px",
          minWidth: "200px"
        },
        className: "text-center stickyStyle fristColSticky"
      },
      {
        displayName: "商品名",
        sortField: "menuName",
        sortType: "string",
        style: {
          top: "0",
          left: "409px",
          zIndex: 99,
          maxWidth: " 200px",
          width: "200px",
          minWidth: "200px"
        },
        className: "text-center stickyStyle fristColSticky"
      },
      {
        displayName: "単価",
        sortField: "unitPrice",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "原価",
        sortField: "cost",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "数量",
        sortField: "totalItem",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "金額",
        sortField: "totalPrice",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "売上構成比",
        sortField: "compositionRatio",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "累計構成比",
        sortField: "cumulativeRatio",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "合計原価",
        sortField: "totalCost",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "利益",
        sortField: "profit",
        sortType: "number",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "大部門",
        sortField: "largeDivisionName",
        sortType: "string",
        style: { top: "0" },
        className: "text-center stickyStyle"
      },
      {
        displayName: "部門",
        sortField: "smallDivisionName",
        sortType: "string",
        style: { top: "0" },
        className: "text-center stickyStyle"
      }
    ],
    []
  );

  const CustomInnerElement = React.forwardRef(props => (
    <tbody {...props}>
      {props.children}
      {/* footer */}
      <tr>
        <td
          colSpan={4}
          style={{ top: "0", left: "0", zIndex: 99 }}
          className="text-center stickyStyle fristColSticky"
        >
          <span>合計</span>
        </td>
        <td className="text-right">
          <span>-</span>
        </td>
        <td className="text-right">
          <span>-</span>
        </td>
        <td className="text-right">
          <span>{itemReport.total.totalItem}</span>
        </td>
        <td className="text-right">
          <span>{itemReport.total.totalPrice}</span>
        </td>
        <td className="text-right">
          <span>-</span>
        </td>
        <td className="text-right">
          <span>-</span>
        </td>
        <td className="text-right">
          <span>{itemReport.total.totalCost}</span>
        </td>
        <td className="text-right">
          <span>{itemReport.total.profit}</span>
        </td>
        <td className="text-right">
          <span>-</span>
        </td>
        <td className="text-right">
          <span>-</span>
        </td>
      </tr>
    </tbody>
  ));

  const TableWrapper = forwardRef<
    HTMLTableElement,
    { children?: React.ReactNode }
  >((props, ref) => (
    <table ref={ref} className="table table-bordered text-nowrap">
      <thead>
        <tr>
          {sortColumns.map((col: SortColumn, index: number) => (
            <th
              key={index}
              style={col.style}
              className={col.className}
              onClick={e => sortByFieldPath(col.sortField, col.sortType)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative"
                }}
              >
                <span style={{ margin: "auto" }}>{col.displayName}</span>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    marginTop: "-3px"
                  }}
                >
                  {sortField.fieldPath === col.sortField && (
                    <SortIcon isSortedDesc={sortField.sort === "desc"} />
                  )}
                  {sortField.fieldPath !== col.sortField && (
                    <Icon
                      type="sortDefault"
                      color={grayScale.gray100}
                      size="25px"
                    />
                  )}
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      {props.children}
    </table>
  ));

  /** ソート実装 */
  const [
    { sortField, sortedDataSource: dataSort },
    { sortByFieldPath }
  ] = useSortTable(itemReport.items, DEFAULT_SORT_FIELD);

  const handleItemsRendered = useCallback(
    ({ visibleStartIndex, visibleStopIndex }) => {
      // ここで実際に表示されているアイテムの開始・終了インデックスが分かる
      // データがあり、かつ表示範囲が確定したらローディングをOFFにする
      if (dataSort.length > 0 && visibleStopIndex >= 0) {
        setIsLoading(false);
      }
    },
    [dataSort, setIsLoading]
  );

  // ------------------------------------------------------------------
  // 出力
  // ------------------------------------------------------------------
  /** CSV・Excel出力実装 */
  const {
    downloadCntOrderReportCsv,
    downloadCntOrderReportExcel
  } = useDownload();

  /** 出力用データ */
  const outputDataList = useMemo(() => dataSort.map(d => d.getOutputData()), [
    dataSort
  ]);

  /** 出力用合計行 */
  const totalInformation = useMemo(
    () => [
      [
        "合計",
        "",
        "",
        "",
        "-",
        "-",
        itemReport?.total?.totalItem || 0,
        itemReport?.total?.totalPrice || 0,
        "-",
        "-",
        itemReport?.total?.totalCost || 0,
        itemReport?.total?.profit || 0,
        "-",
        "-"
      ]
    ],
    [itemReport.total]
  );
  /** 出力用ヘッダ情報 */
  const headerInformation = useMemo(
    () => [sortColumns.map(s => s.displayName)],
    []
  );
  /**
   * Excel出力用セル結合情報
   * NOTE: [firstRow, lastRow, firstCol, lastCol] の配列で結合するセル範囲を指定する
   */
  const mergedRegions = useMemo(
    () => [
      [
        itemReport.items ? itemReport.items.length + 7 : 7,
        itemReport.items ? itemReport.items.length + 7 : 7,
        0,
        3
      ]
    ],
    [itemReport.items]
  );
  /** 対象店舗 */
  const targetStoresData = `対象店舗：${orgName}`;

  const data = {
    formName: "商品別ABC数量順",
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    targetDay: `対象曜日： ${getTargetWeekText(formik)}`,
    targetTime: `対象時間：${itemStartTime}時~${itemEndTime}時`,
    headerInformation,
    searchResult: outputDataList,
    totalInformation
  };

  const dataExcel = {
    formName: "商品別ABC数量順",
    targetPeriod: targetPeriodData,
    targetStores: targetStoresData,
    targetDay: `対象曜日： ${getTargetWeekText(formik)}`,
    targetTime: `対象時間：${itemStartTime}時~${itemEndTime}時`,
    headerInformation,
    searchResult: outputDataList,
    totalInformation,
    mergedRegions,
    codeColumnIndex: [2]
  };

  const handleExportCSV = () => downloadCntOrderReportCsv(data, setIsLoading);
  const handleExportExcel = () =>
    downloadCntOrderReportExcel(dataExcel, setIsLoading);

  return (
    <div>
      {roleScreen && roleScreen.downloadFlag === 1 && (
        <FlexBox>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportCSV}
              iconType="download"
              text=" CSV出力"
            />
          </FlexBoxItem>
          <FlexBoxItem>
            <IconLabelButton
              onClick={handleExportExcel}
              iconType="download"
              text=" EXCEL出力"
            />
          </FlexBoxItem>
        </FlexBox>
      )}

      <div style={{ margin: "15px" }}>
        <div
          className="templateTable newDesignTable"
          style={{
            height: "auto",
            minHeight: "10%",
            maxHeight: "calc(100vh - 300px)",
            paddingBottom: isMacOs ? "35px" : "1px"
          }}
        >
          {dataSort.length > 0 ? (
            <List
              height={dataSort.length}
              itemCount={dataSort.length}
              itemSize={1}
              width="100%"
              itemData={dataSort}
              outerElementType={TableWrapper}
              innerElementType={CustomInnerElement}
              onItemsRendered={handleItemsRendered}
            >
              {Row}
            </List>
          ) : (
            <table className="table table-bordered text-nowrap">
              <thead>
                <tr>
                  {sortColumns.map((col: SortColumn, index: number) => (
                    <th
                      key={index}
                      style={col.style}
                      className={col.className}
                      onClick={e =>
                        sortByFieldPath(col.sortField, col.sortType)
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          position: "relative"
                        }}
                      >
                        <span style={{ margin: "auto" }}>
                          {col.displayName}
                        </span>
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            marginTop: "-3px"
                          }}
                        >
                          {sortField.fieldPath === col.sortField && (
                            <SortIcon
                              isSortedDesc={sortField.sort === "desc"}
                            />
                          )}
                          {sortField.fieldPath !== col.sortField && (
                            <Icon
                              type="sortDefault"
                              color={grayScale.gray100}
                              size="25px"
                            />
                          )}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={14}
                    style={{ left: 0 }}
                    className="text-center stickyStyle fristColSticky"
                  >
                    データはありません。
                  </td>
                </tr>
                {/* footer */}
                <tr>
                  <td
                    colSpan={4}
                    style={{ top: "0", left: "0", zIndex: 99 }}
                    className="text-center stickyStyle fristColSticky"
                  >
                    <span>合計</span>
                  </td>
                  <td className="text-right">
                    <span>-</span>
                  </td>
                  <td className="text-right">
                    <span>-</span>
                  </td>
                  <td className="text-right">
                    <span>{itemReport.total.totalItem}</span>
                  </td>
                  <td className="text-right">
                    <span>{itemReport.total.totalPrice}</span>
                  </td>
                  <td className="text-right">
                    <span>-</span>
                  </td>
                  <td className="text-right">
                    <span>-</span>
                  </td>
                  <td className="text-right">
                    <span>{itemReport.total.totalCost}</span>
                  </td>
                  <td className="text-right">
                    <span>{itemReport.total.profit}</span>
                  </td>
                  <td className="text-right">
                    <span>-</span>
                  </td>
                  <td className="text-right">
                    <span>-</span>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemCntOrderTable;
