/** @jsx jsx */
import React, { useRef, useState } from "react";
import { css, jsx, SerializedStyles } from "@emotion/core";
import { grayScale, iconColor, textFontSize } from "components/styles";

type NumericInputProps = {
  value: number;
  onChange: (value: number) => void;
  readOnly?: boolean;
  customStyle?: SerializedStyles;
  maxLength?: number;
};

const styles = {
  style: css({
    border: `solid 1px ${grayScale.gray10}`,
    boxSizing: "border-box",
    borderRadius: "4px",
    width: "100%",
    fontSize: textFontSize.re,
    fontFamily: "inherit",
    padding: "8px"
  }),
  grayOut: css({
    background: iconColor.gray
  })
};

const NumericInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  readOnly = false,
  customStyle,
  maxLength
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isComposing, setIsComposing] = useState(false);

  /**
   * Composition開始時のハンドラ
   */
  const handleCompositionStart = () => {
    setIsComposing(true); // IME入力中を示す
  };

  /**
   * Composition終了時のハンドラ
   */
  const handleCompositionUpdate = (
    e: React.CompositionEvent<HTMLInputElement>
  ) => {
    // 終了後の入力内容を処理
    const currentValue = inputRef.current?.value || "";

    const data = e.data;

    // 数値以外の文字を削除
    const numericValue = data.replace(/[^0-9０-９]/g, "");

    // 全角英数字を半角英数字に変換
    const convertedValue = numericValue.replace(/[０-９]/g, char =>
      String.fromCharCode(char.charCodeAt(0) - 0xfee0)
    );

    const finalValue = currentValue.replace(/,/g, "") + convertedValue;

    const trimmedValue =
      maxLength && finalValue.length > maxLength
        ? finalValue.slice(0, maxLength)
        : finalValue;

    onChange(Number(trimmedValue));

    setTimeout(() => {
      setIsComposing(false); // IME入力終了
    }, 5000);
  };

  const handleFocus = () => {
    // フォーカス時に全選択
    inputRef.current?.select();
  };

  const handleInputChange = () => {
    // IME入力中は変更を無視
    if (isComposing) {
      return;
    }

    const rawValue = inputRef.current?.value || "";

    // 数値以外の入力があれば処理を中断
    if (/[^0-9,]/.test(rawValue)) {
      return;
    }

    // 数値以外の文字を削除
    const numericValue = rawValue.replace(/[^0-9]/g, "");

    const trimmedValue =
      maxLength && numericValue.length > maxLength
        ? numericValue.slice(0, maxLength)
        : numericValue;

    onChange(Number(trimmedValue));
  };

  return (
    <input
      css={css(styles.style, readOnly ? styles.grayOut : {}, customStyle)}
      ref={inputRef}
      type="text"
      value={value.toLocaleString()}
      onFocus={handleFocus}
      onChange={handleInputChange}
      onCompositionStart={handleCompositionStart}
      onCompositionUpdate={handleCompositionUpdate}
      inputMode="numeric" // モバイルで数字キーボードを表示
      autoComplete="off"
    />
  );
};

export default NumericInput;
