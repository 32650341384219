/* eslint-disable no-nested-ternary */
import React from 'react';

import FormTitle from 'components/atoms/Form/FormTitle';
import FormField from 'components/atoms/Form/FormField';
import FormLabel from 'components/atoms/Form/FormLabel';

import VerticalLabelSelectForm from 'components/molecules/VerticalLabelSelectForm';
import RadioSelectForm from 'components/molecules/RadioSelectForm';
// import FormSubmitArea from 'components/atoms/Form/FormSubmitArea';
// import Button from 'components/atoms/Button';
import HourInput from 'components/atoms/Form/HourInput';
import FlexBox from 'components/atoms/FlexBox';
import FormLayout from 'components/atoms/Form/VerticalLabelFormLayout';
import { gridCol } from 'components/styles';
// import TimeInput from 'components/atoms/Form/TimeInput';
import Checkbox from 'components/molecules/Checkbox';
import TimePicker from 'rc-time-picker';

const EmploymentWorkSystemAndBreakSettings: React.FC<{
  formik: any
}> = ({
  formik,
}) => {
  const autoRecessItems = [];
  const recessNum = 3;
  // const [numberAutoRecess, setNumberAutoRecess] = React.useState(1);
  // if (formik.values.autoRecessWorkConditionTime3 > 0) {
  //   recessNum = 3;
  // } else if (formik.values.autoRecessWorkConditionTime2 > 0) {
  //   recessNum = 2;
  // }
  for (let i = 0; i < recessNum;) {
    autoRecessItems.push(i);
    i += 1;
  }
  // const [numberAutoRecess, setNumberAutoRecess] = React.useState(recessNum);
  return (
    <>
      <FormTitle
        title="勤務体系・休憩設定"
      />
      <div>
        <FormField>
          <RadioSelectForm
            label="勤務体系"
            name="workSystem"
            items={[
              {
                label: '標準',
                value: '0',
              },
              {
                label: '変形労働',
                value: '1',
              },
            ]}
            value={String(formik.values.workSystem)}
            setValue={formik.handleChange}
          />
        </FormField>

        <div style={String(formik.values.workSystem) === '0' ? {} : { display: 'none' }}>
          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ width: gridCol.grid12 }}>
                <FormLabel
                  label="標準勤務時間"
                />
              </div>
              {/* <div style={{ width: '350px' }}> */}
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="startTime"
                  value={formik.values.startTime}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('startTime', val)}
                /> */}
                <TimePicker
                  name="startTime"
                  format="HH:mm"
                  value={formik.values.startTimeMoment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('startTime', val ? val.format('HH:mm') : '00:00')}
                />
              </div>
              <div style={{
                width: '55px', float: 'left',
              }}
              >
                <Checkbox
                  id="isStartTimeNextDay"
                  name="isStartTimeNextDay"
                  label="翌日"
                  value={String(formik.values.isStartTimeNextDay)}
                  checked={Boolean(formik.values.isStartTimeNextDay)}
                  onChange={formik.handleChange}
                />
              </div>
              <div style={{
                width: '30px', float: 'left',
              }}
              >
                〜
              </div>
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="endTime"
                  value={formik.values.endTime}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('endTime', val)}
                /> */}
                <TimePicker
                  name="endTime"
                  format="HH:mm"
                  value={formik.values.endTimeMoment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('endTime', val ? val.format('HH:mm') : '00:00')}
                />
              </div>
              <div style={{
                width: '55px', float: 'left',
              }}
              >
                <Checkbox
                  id="isEndTimeNextDay"
                  name="isEndTimeNextDay"
                  label="翌日"
                  value={String(formik.values.isEndTimeNextDay)}
                  checked={Boolean(formik.values.isEndTimeNextDay)}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            {/* </div> */}
          </FormField>
        </div>

        <div style={String(formik.values.workSystem) === '0' ? {} : { display: 'none' }}>
          <FormField>
            <RadioSelectForm
              label="自動休憩機能"
              name="useAutoRecess"
              items={[
                {
                  label: '打刻休憩を使用',
                  value: '0',
                },
                {
                  label: '自動休憩を使用',
                  value: '1',
                },
                {
                  label: '標準勤務の休憩を使用する',
                  value: '2',
                },
              ]}
              value={String(formik.values.useAutoRecess)}
              setValue={formik.handleChange}
            />
          </FormField>
        </div>

        <div style={String(formik.values.workSystem) === '1' ? {} : { display: 'none' }}>
          <FormField>
            <RadioSelectForm
              label="自動休憩機能"
              name="useAutoRecess"
              items={[
                {
                  label: '打刻休憩を使用',
                  value: '0',
                },
                {
                  label: '自動休憩を使用',
                  value: '1',
                },
                {
                  label: 'シフトで休憩を設定',
                  value: '2',
                },
              ]}
              value={String(formik.values.useAutoRecess)}
              setValue={formik.handleChange}
            />
          </FormField>
        </div>

        {
          (String(formik.values.useAutoRecess) === "1" ) && (
            <React.Fragment>  
              <FormField>
                <RadioSelectForm
                  label="打刻修正申請画面で自動休憩無効を許可"
                  name="isDisplayAutoBreak"
                  items={[
                    {
                      label: 'しない',
                      value: '0',
                    },
                    {
                      label: 'する',
                      value: '1',
                    }
                  ]}
                  value={String(formik.values.isDisplayAutoBreak === true ? 1 : 0) }
                  setValue={(e) => {
                    formik.setFieldValue('isDisplayAutoBreak', e.target.value === '1' ? true : false);
                  }}
                />
                <span></span>
              </FormField>
            </React.Fragment>
          )
        }

        <div style={String(formik.values.useAutoRecess) === '1' ? {} : { display: 'none' }}>
          {autoRecessItems.map((value, key) => (
            <FormField>
              <div className="col col-lg-6" style={{ marginRight: '12px' }}>
                <FormLayout
                  label={(
                    <FormLabel
                      label={`自動休憩${String(value + 1)}`}
                      // label=""
                    />
                    )}
                  input={(
                    <FlexBox>
                      <FormLabel
                        label="労働時間が"
                      />
                      <div style={{ width: '120px' }}>
                        <HourInput
                          name={`autoRecessWorkConditionTime${String(value + 1)}`}
                          value={
                            value === 0
                              ? formik.values.autoRecessWorkConditionTime1
                              : value === 1
                                ? formik.values.autoRecessWorkConditionTime2
                                : formik.values.autoRecessWorkConditionTime3
                          }
                          label="分"
                          min={0}
                          max={1440}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <FormLabel
                        label="を超える場合は"
                      />
                      <div style={{ width: '120px' }}>
                        <HourInput
                          name={`autoRecessGrantTime${String(value + 1)}`}
                          value={
                            value === 0
                              ? formik.values.autoRecessGrantTime1
                              : value === 1
                                ? formik.values.autoRecessGrantTime2
                                : formik.values.autoRecessGrantTime3
                          }
                          label="分"
                          min={0}
                          max={1440}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <FormLabel
                        label="の休憩を与える"
                      />
                    </FlexBox>
                    )}
                />
              </div>
            </FormField>
          ))}

          {/* <FormSubmitArea>
            <Button
              text="自動休憩を追加"
              onClick={() => setNumberAutoRecess(numberAutoRecess + 1)}
              fullwidth={true}
              disabled={(numberAutoRecess > 2)}
            />
          </FormSubmitArea> */}

          <FormField>
            <RadioSelectForm
              label="自動休憩を引く項目の優先順位"
              name="autoRecessPriorityOfSubtract"
              items={[
                {
                  label: '深夜 > 普通',
                  value: '1',
                },
                {
                  label: '普通 > 深夜',
                  value: '0',
                },
              ]}
              value={String(formik.values.autoRecessPriorityOfSubtract)}
              setValue={formik.handleChange}
            />
          </FormField>

          <VerticalLabelSelectForm
            label="打刻休憩と自動休憩の重複時"
            name="autoRecessDuplication"
            value={String(formik.values.autoRecessDuplication)}
            setValue={(val: string) => formik.setFieldValue('autoRecessDuplication', val)}
            options={[
              {
                label: '打刻休憩のみを休憩時間とする',
                value: '0',
              },
              {
                label: '自動休憩を休憩時間とする',
                value: '1',
              },
              {
                label: '打刻休憩と自動休憩を合算したものを休憩時間とする',
                value: '2',
              },
            ]}
          />
        </div>

        <div style={String(formik.values.workSystem) === '0' && String(formik.values.useAutoRecess) === '2' ? {} : { display: 'none' }}>
          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ width: gridCol.grid12 }}>
                <FormLabel
                  label="標準勤務休憩時間1"
                />
              </div>
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="recessStartTime1"
                  value={formik.values.recessStartTime1}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('recessStartTime1', val)}
                /> */}
                <TimePicker
                  name="recessStartTime1"
                  format="HH:mm"
                  value={formik.values.recessStartTime1Moment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('recessStartTime1', val ? val.format('HH:mm') : '00:00')}
                />

              </div>
              <div style={{
                width: '55px', float: 'left',
              }}
              >
                <Checkbox
                  id="isRecessStartTimeNextDay1"
                  name="isRecessStartTimeNextDay1"
                  label="翌日"
                  value={String(formik.values.isRecessStartTimeNextDay1)}
                  checked={Boolean(formik.values.isRecessStartTimeNextDay1)}
                  onChange={formik.handleChange}
                />
              </div>
              <div style={{
                width: '30px', float: 'left',
              }}
              >
                〜
              </div>
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="recessEndTime1"
                  value={formik.values.recessEndTime1}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('recessEndTime1', val)}
                /> */}
                <TimePicker
                  name="recessEndTime1"
                  format="HH:mm"
                  value={formik.values.recessEndTime1Moment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('recessEndTime1', val ? val.format('HH:mm') : '00:00')}
                />
              </div>
              <div style={{
                width: '55px', float: 'left', marginTop: '5px',
              }}
              >
                <Checkbox
                  id="isRecessEndTimeNextDay1"
                  name="isRecessEndTimeNextDay1"
                  label="翌日"
                  value={String(formik.values.isRecessEndTimeNextDay1)}
                  checked={Boolean(formik.values.isRecessEndTimeNextDay1)}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </FormField>
          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ width: gridCol.grid12 }}>
                <FormLabel
                  label="標準勤務休憩時間2"
                />
              </div>
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="recessStartTime2"
                  value={formik.values.recessStartTime2}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('recessStartTime2', val)}
                /> */}
                <TimePicker
                  name="recessStartTime2"
                  format="HH:mm"
                  value={formik.values.recessStartTime2Moment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('recessStartTime2', val ? val.format('HH:mm') : '00:00')}
                />
              </div>
              <div style={{
                width: '55px', float: 'left',
              }}
              >
                <Checkbox
                  id="isRecessStartTimeNextDay2"
                  name="isRecessStartTimeNextDay2"
                  label="翌日"
                  value={String(formik.values.isRecessStartTimeNextDay2)}
                  checked={Boolean(formik.values.isRecessStartTimeNextDay2)}
                  onChange={formik.handleChange}
                />
              </div>
              <div style={{
                width: '30px', float: 'left',
              }}
              >
                〜
              </div>
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="recessEndTime2"
                  value={formik.values.recessEndTime2}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('recessEndTime2', val)}
                /> */}
                <TimePicker
                  name="recessEndTime2"
                  format="HH:mm"
                  value={formik.values.recessEndTime2Moment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('recessEndTime2', val ? val.format('HH:mm') : '00:00')}
                />
              </div>
              <div style={{
                width: '55px', float: 'left', marginTop: '5px',
              }}
              >
                <Checkbox
                  id="isRecessEndTimeNextDay2"
                  name="isRecessEndTimeNextDay2"
                  label="翌日"
                  value={String(formik.values.isRecessEndTimeNextDay2)}
                  checked={Boolean(formik.values.isRecessEndTimeNextDay2)}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </FormField>
          <FormField>
            <div style={{ width: gridCol.grid12 }}>
              <div style={{ width: gridCol.grid12 }}>
                <FormLabel
                  label="標準勤務休憩時間3"
                />
              </div>
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="recessStartTime3"
                  value={formik.values.recessStartTime3}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('recessStartTime3', val)}
                /> */}
                <TimePicker
                  name="recessStartTime3"
                  format="HH:mm"
                  value={formik.values.recessStartTime3Moment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('recessStartTime3', val ? val.format('HH:mm') : '00:00')}
                />
              </div>
              <div style={{
                width: '55px', float: 'left',
              }}
              >
                <Checkbox
                  id="isRecessStartTimeNextDay3"
                  name="isRecessStartTimeNextDay3"
                  label="翌日"
                  value={String(formik.values.isRecessStartTimeNextDay3)}
                  checked={Boolean(formik.values.isRecessStartTimeNextDay3)}
                  onChange={formik.handleChange}
                />
              </div>
              <div style={{
                width: '30px', float: 'left',
              }}
              >
                〜
              </div>
              <div style={{ width: '150px', float: 'left' }}>
                {/* <TimeInput
                  name="recessEndTime3"
                  value={formik.values.recessEndTime3}
                  onChange={() => {}}
                  setValue={(val: string) => formik.setFieldValue('recessEndTime3', val)}
                /> */}
                <TimePicker
                  name="recessEndTime3"
                  format="HH:mm"
                  value={formik.values.recessEndTime3Moment}
                  showSecond={false}
                  onChange={(val) => formik.setFieldValue('recessEndTime3', val ? val.format('HH:mm') : '00:00')}
                />
              </div>
              <div style={{
                width: '55px', float: 'left', marginTop: '5px',
              }}
              >
                <Checkbox
                  id="isRecessEndTimeNextDay3"
                  name="isRecessEndTimeNextDay3"
                  label="翌日"
                  value={String(formik.values.isRecessEndTimeNextDay3)}
                  checked={Boolean(formik.values.isRecessEndTimeNextDay3)}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </FormField>
        </div>

        <FormField>
          <RadioSelectForm
            label="遅刻・早退の判定"
            name="isLateEarlierJudge"
            items={[
              {
                label: "しない",
                value: "0"
              },
              {
                label: "する",
                value: "1"
              }
            ]}
            value={String(formik.values.isLateEarlierJudge === true ? 1 : 0)}
            setValue={e => {
              formik.setFieldValue(
                "isLateEarlierJudge",
                e.target.value === "1"
              );
            }}
          />
        </FormField>
      </div>
    </>
  );
};

export default EmploymentWorkSystemAndBreakSettings;
