import { useEffect, useState } from "react";
import * as api from "api/monthlyReport";
import MonthlyReportDomain from "domain/master/storeManagement/monthlyReport";
import { useFormik } from "formik";
import ItemReportDomain, {
  ItemReport
} from "domain/master/storeManagement/ItemReport";
import ItemCategoryReportDomain, {
  ItemCategoryReport
} from "domain/master/storeManagement/ItemCategoryReport";
import useToastNotification from "hooks/useToastNotification";

const useItemsReport = (
  orgCode: string,
  monthlyStartDate: string,
  startTime: string,
  monthlyEndDate: string,
  endTime: string,
  flag: number,
  categoryAry: any
) => {
  const [items, setItems] = useState<Array<ItemReportDomain>>([]);
  const [itemsTotal, setItemsTotal] = useState<ItemReportDomain>(
    ItemReportDomain.generateInitial()
  );
  const [itemCategories, setItemCategories] = useState<
    Array<ItemCategoryReportDomain>
  >([]);
  const [itemCategoriesTotal, setItemCategoriesTotal] = useState<
    ItemCategoryReportDomain
  >(ItemCategoryReportDomain.generateInitial());
  const onSubmit = () => {};
  const formik = useFormik({
    initialValues: MonthlyReportDomain.generateInitial(),
    onSubmit
  });
  const [getIsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    // 初期化
    setItems([]);
    setItemCategories([]);
    const companyCode = sessionStorage.getItem("loginUser.companyCode") || "";
    let path = "salesmanagement/abcanalysis/item/sales";

    if (flag === 2) {
      path = "salesmanagement/abcanalysis/item/quantity";
    } else if (flag === 3) {
      path = "salesmanagement/abcanalysis/category/sales";
    } else if (flag === 4) {
      path = "salesmanagemet/abcanalysis/category/quantity";
    }

    const dateOfweek = [
      {
        itemPub: formik.values.itemPub,
        itemSat: formik.values.itemSat,
        itemSun: formik.values.itemSun,
        itemMon: formik.values.itemMon,
        itemTue: formik.values.itemTue,
        itemWed: formik.values.itemWed,
        itemTur: formik.values.itemTur,
        itemFri: formik.values.itemFri
      }
    ];
    setIsLoading(true);
    api
      .getItemsSalesOrderReport(
        companyCode,
        orgCode,
        monthlyStartDate,
        startTime,
        monthlyEndDate,
        endTime,
        path,
        dateOfweek,
        categoryAry
      )
      .then((response: any) => {
        if (isSubscribed) {
          if (flag === 1 || flag === 2) {
            setItems(
              response.items.map(
                (item: ItemReport) => new ItemReportDomain(item)
              )
            );
            setItemsTotal(new ItemReportDomain(response.slitTotal));
          }
          if (flag === 3 || flag === 4) {
            setItemCategories(
              response.items.map(
                (item: ItemCategoryReport) => new ItemCategoryReportDomain(item)
              )
            );
            setItemCategoriesTotal(
              new ItemCategoryReportDomain(response.slitTotal)
            );
          }
        }
        // データが存在する場合は、テーブルがレンダリングされたタイミングでローディングを終了する
        if (response.items.length === 0) {
          setIsLoading(false);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    orgCode,
    monthlyStartDate,
    flag,
    formik.values.itemPub,
    formik.values.itemSat,
    formik.values.itemSun,
    formik.values.itemMon,
    formik.values.itemTue,
    formik.values.itemWed,
    formik.values.itemTur,
    formik.values.itemFri,
    startTime,
    monthlyEndDate,
    endTime,
    categoryAry
  ]);

  return {
    itemReport: {
      items: items,
      total: itemsTotal
    },
    itemCategoryReport: {
      items: itemCategories,
      total: itemCategoriesTotal
    },
    formik,
    getIsLoading,
    setIsLoading
  };
};

type ActionType = "getFetchOption" | "getFetchOptionforeCast";

const ActionType: { [key in ActionType]: ActionType } = {
  getFetchOption: "getFetchOption",
  getFetchOptionforeCast: "getFetchOptionforeCast"
};

export const useDownload = () => {
  const { errorNotification } = useToastNotification();

  const downloadMonthlyReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportCsv(monthlyReport, "商品別ABC売上順.csv")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadMonthlyReportExcel = (
    monthlyReport: any,
    setIsLoading: any
  ) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportExcel(monthlyReport, "商品別ABC売上順.xlsx")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadCntOrderReportCsv = (monthlyReport: any, setIsLoading: any) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportCsv(monthlyReport, "商品別ABC数量順.csv")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadCntOrderReportExcel = (
    monthlyReport: any,
    setIsLoading: any
  ) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportExcel(monthlyReport, "商品別ABC数量順.xlsx")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadCategorySalesOrderReportCsv = (
    monthlyReport: any,
    setIsLoading: any
  ) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportCsv(monthlyReport, "部門別ABC売上順.csv")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadCategorySalesOrderReportExcel = (
    monthlyReport: any,
    setIsLoading: any
  ) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportExcel(monthlyReport, "部門別ABC売上順.xlsx")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadCategoryCntOrderReportCsv = (
    monthlyReport: any,
    setIsLoading: any
  ) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportCsv(monthlyReport, "部門別ABC数量順.csv")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadCategoryCntOrderReportExcel = (
    monthlyReport: any,
    setIsLoading: any
  ) => {
    setIsLoading(true);
    api
      .downloadMonthlyReportExcel(monthlyReport, "部門別ABC数量順.xlsx")
      .catch(error => {
        if (error.response.status === 500) {
          errorNotification("サーバー側でエラーが発生しました。");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    downloadMonthlyReportCsv,
    downloadMonthlyReportExcel,
    downloadCntOrderReportCsv,
    downloadCntOrderReportExcel,
    downloadCategorySalesOrderReportCsv,
    downloadCategorySalesOrderReportExcel,
    downloadCategoryCntOrderReportCsv,
    downloadCategoryCntOrderReportExcel
  };
};

export default useItemsReport;
