import React, { useState } from "react";
import { css } from "@emotion/core";

import { productColor, grayScale, iconColor } from "components/styles";
import FormContents from "components/atoms/Form/FormContents";
import FlexBox from "components/atoms/FlexBox";
import FlexBoxItem from "components/atoms/FlexBoxItem";
import DatePicker from "components/molecules/DatePicker";
import BlockUI from "components/molecules/BlockUi";
import PieChart from "components/molecules/PieChart";
import OrganizationFilteredTree from "components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree";
import moment from "moment";
import useSalesMonthlyReport, { useReportGraph } from "./hooks";
import SalesReportTable from "./SalesReportTable";
import AccordionPanel from "components/molecules/AccordionPanel";

const SalesMonthlyReportLayout = () => {
  const {
    orgCode,
    setOrgCode,
    selectedMonth,
    countAdvertising,
    setSelectedMonth,
    generalData,
    dataKeyName,
    dayPlanData,
    monthlyPlanData,
    advertisingData,
    isLoading,
    staffCode,
    setCategoryArr,
    setting,
    countExpenses,
    expensesData
  } = useSalesMonthlyReport();
  const reportGraphData = useReportGraph(
    monthlyPlanData,
    generalData?.month,
    generalData?.setting
  );
  const [orgTreesOptions, setOrgTreesOptions] = useState([]);
  const [isExpand, setExpand] = useState(false);

  return (
    <div>
      <FormContents>
        <FlexBox>
          <FlexBoxItem basis="20%">
            <DatePicker
              dateFormat="yyyy年MM月"
              date={selectedMonth}
              changeDate={(date: Date) => {
                if (date !== null) {
                  setSelectedMonth(date);
                }
              }}
              isFullWidth={true}
              showMonthYearPicker={true}
            />
          </FlexBoxItem>

          <FlexBoxItem basis="1%" />
        </FlexBox>
        <OrganizationFilteredTree
          functionType={3}
          orgCallback={(args: string | Array<string>) => {
            setOrgCode(String(args));
          }}
          initOrgValue={String(orgCode)}
          staffCode={staffCode}
          orgLabel="店舗名"
          addAllItem={true}
          orgCategoryCallback={(args: Array<string>) => {
            setCategoryArr(args);
          }}
          targetdayForm={moment(
            new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1)
          ).toDate()}
          targetdayTo={moment(
            new Date(
              selectedMonth.getFullYear(),
              selectedMonth.getMonth() + 1,
              0
            )
          ).toDate()}
          orgOrgOptionListCallback={setOrgTreesOptions}
          orgNameCallback={(args: string) => {
            sessionStorage.setItem("salesMonthlyReport.orgName", args);
          }}
        />

        <BlockUI blocking={isLoading}>
          <AccordionPanel
            text="統計グラフ"
            color={iconColor.blue}
            callBack={isExpand => {
              setExpand(isExpand);
            }}
            defaultExpand={isExpand}
          >
            <FlexBox
              customStyle={css({ overflowX: "scroll", paddingBottom: "20px;" })}
            >
              <PieChart
                width={500}
                colorSet={[
                  "#FFAB4F",
                  "#EE423D",
                  "#843FA0",
                  "#1F8EFA",
                  "#05C985",
                  "#869AAC"
                ]}
                data={reportGraphData.costData}
                centerLabel="売上高"
                centerText={`¥${reportGraphData.netSales.toLocaleString()}`}
                bottomLabel="営業利益"
                bottomText={`¥${reportGraphData.operatingIncome.toLocaleString()}`}
                legendData={reportGraphData.costLegendData}
              />
              <PieChart
                width={600}
                colorSet={[
                  productColor.primary,
                  grayScale.gray50,
                  grayScale.gray10
                ]}
                data={reportGraphData.customersData}
                centerLabel="予算達成の客数"
                centerText={`${reportGraphData.goalGuestCountBudget.toLocaleString()}人`}
                bottomLabel="現時点での客単価"
                bottomText={`¥${reportGraphData.unitPricePerCustomer.toLocaleString()}`}
                legendWidth={200}
                legendData={reportGraphData.customersLegendData}
              />
            </FlexBox>
          </AccordionPanel>
        </BlockUI>
      </FormContents>

      <FormContents>
        <BlockUI blocking={isLoading}>
          <SalesReportTable
            orgCode={orgCode}
            orgTreesOptions={orgTreesOptions}
            generalData={generalData || []}
            dataKeyName={dataKeyName}
            dayPlanData={dayPlanData}
            monthlyPlanData={monthlyPlanData}
            countAdvertising={countAdvertising}
            advertisingData={advertisingData}
            countExpenses={countExpenses}
            expensesData={expensesData}
            setting={setting || []}
          />
        </BlockUI>
      </FormContents>
    </div>
  );
};
export default SalesMonthlyReportLayout;
