/** @jsx jsx */
/* eslint-disable max-len */
import React, { useMemo } from "react";
import SelectForm from "components/molecules/SelectForm";
import TimeRecalculationDomain from "domain/master/atendenceManagement/timeRecalculation";
import DataTable from "components/organismos/DataTable/DataTable";
import { Column } from "react-table";
import productColor, { textFontSize, utilityColor } from "components/styles";
import {
  useEmploymentTypeOptions,
  useStaffOptions
} from "hooks/useEmploymentOptions";
import RadioSelectForm from "components/molecules/RadioSelectForm";
import FormContents from "components/atoms/Form/FormContents";
import FormSubmitArea from "components/atoms/Form/FormSubmitArea";
import { css, jsx } from "@emotion/core";
import Button from "components/atoms/Button";
import BodyText from "components/atoms/BodyText";
import ToastModal from "components/organismos/ToastModal";
import FormField from "components/atoms/Form/FormField";
import ConfirmModal from "components/organismos/ConfirmModal";
import FlexBox from "components/atoms/FlexBox";
import Icon from "components/atoms/Icon";
import FormLabel from "components/atoms/Form/FormLabel";
import DatePicker from "components/molecules/DatePicker";
import { OptionType } from "components/atoms/Select";
import BlockUI from "components/molecules/BlockUi";
import OrganizationFilteredTree from "components/organismos/organization/OrganizationFilteredTree/OrganizationFilteredTree";
import moment from "moment";
import useTimeRecalculation, { useUpdateTimeRecalcution } from "./hooks";

// create style form
const styles = {
  form: css({
    paddingTop: "15px"
  }),
  wrapper: css({
    display: "flex",
    width: "150px",
    float: "left"
  }),
  container: css({
    padding: "4px 0"
  }),
  label: css({
    flexBasis: "100px"
  }),
  select: css({
    flexGrow: 4,
    minWidth: "100px",
    paddingLeft: "20px"
  }),
  digestionDays: css({
    width: "120px"
  }),
  betweenDays: css({
    width: "50px",
    textAlign: "center"
  })
};

const TimeRecalculationForm: React.FC = () => {
  const {
    timeRecalculation,
    setTimeRecalculation,
    isFinished,
    isLoading,
    setLoading,
    setIsFinished,
    isRecalculateState,
    setIsRecalculateState,
    checkRecalculateFinished,
    handleReload,
    arrNecessaryShiftCalc
  } = useTimeRecalculation();
  const {
    formik,
    toastModalOpen,
    toastMessage,
    closeToastModalOpen,
    setAction,
    confirmModalContent,
    confirmModalOpen,
    closeConfirmModal,
    submitConfirmModal,
    ActionType,
    checkTargetRange,
    categoryArr,
    setCategoryArr
  } = useUpdateTimeRecalcution(
    setTimeRecalculation,
    timeRecalculation,
    isFinished,
    setIsFinished,
    isRecalculateState,
    setIsRecalculateState,
    checkRecalculateFinished,
    isLoading,
    setLoading
  );

  let employmentOptions: Array<OptionType> = [];
  let staffOptions: Array<OptionType> = [];
  const functionType = 2;

  const checkTarget = checkTargetRange(
    formik.values.targetDateFrom,
    formik.values.targetDateTo,
    true
  );
  if (checkTarget) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    employmentOptions = useEmploymentTypeOptions(
      formik.values.targetDateFrom,
      formik.values.targetDateTo
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    staffOptions = useStaffOptions(
      formik.values.orgCode || "all",
      formik.values.targetDateFrom,
      formik.values.targetDateTo,
      functionType,
      categoryArr
    );
  }

  const staffCode = sessionStorage.getItem("loginUser.staffCode") || "";

  // Set default selected org
  useMemo(() => {
    // setorgCode(sessionStorage.getItem('selectedOrgCode')!);
    formik.setFieldValue("orgCode", "");

    // setEmploymentId(sessionStorage.getItem('selectedStaffCode')!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: Array<Column<TimeRecalculationDomain>> = useMemo(
    () => [
      {
        Header: "対象期間",
        accessor: "targetEndDateStr"
      },
      {
        Header: "店舗コード",
        accessor: "orgCode"
      },
      {
        Header: "組織名",
        accessor: "orgName"
      },
      {
        Header: "雇用形態",
        accessor: "employmentName"
      },
      {
        Header: "スタッフコード",
        accessor: "staffCode"
      },
      {
        Header: "スタッフ",
        accessor: "staffName"
      },
      {
        Header: "シフト件数",
        accessor: "targetShiftCountStr"
      },
      {
        Header: "実績件数",
        accessor: "targetAchievementCount"
      },
      {
        Header: "状態",
        accessor: "stateFlgString"
      }
    ],
    []
  );

  //
  const handleCalcTarget = () => {
    setAction(ActionType.handleCalcTarget);
    formik.handleSubmit();
  };

  const handleClickCalc = () => {
    setAction(ActionType.handleClickCalc);
    formik.handleSubmit();
  };

  return (
    <div>
      <BlockUI blocking={!isLoading}>
        <FormContents>
          <form onSubmit={formik.handleSubmit}>
            <FormField displayBlock={true} disabled={!isFinished}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="対象期間" />
                </div>
                <div css={styles.digestionDays}>
                  <DatePicker
                    dateFormat="yyyy年MM月"
                    date={formik.values.targetDateFrom}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        formik.setFieldValue(
                          "targetDateFrom",
                          date || new Date()
                        );
                        checkTargetRange(
                          date || new Date(),
                          formik.values.targetDateTo
                        );
                      }
                    }}
                    showMonthYearPicker={true}
                    isFullWidth={true}
                    readOnly={!isFinished}
                  />
                </div>
                <div css={styles.betweenDays}>～</div>
                <div css={styles.digestionDays}>
                  <DatePicker
                    dateFormat="yyyy年MM月"
                    date={formik.values.targetDateTo}
                    changeDate={(date: Date) => {
                      if (date !== null) {
                        formik.setFieldValue(
                          "targetDateTo",
                          date || new Date()
                        );
                        checkTargetRange(
                          formik.values.targetDateFrom,
                          date || new Date()
                        );
                      }
                    }}
                    showMonthYearPicker={true}
                    isFullWidth={true}
                    readOnly={!isFinished}
                  />
                </div>
              </FlexBox>
            </FormField>

            <OrganizationFilteredTree
              functionType={functionType}
              orgCallback={(args: string | Array<string>) => {
                sessionStorage.setItem("selectedOrgCode", String(args));
                formik.setFieldValue("orgCode", String(args));
                formik.setFieldValue("staffCode", "all");
              }}
              orgCategoryCallback={(args: Array<string>) => {
                setCategoryArr(args);
              }}
              targetdayTo={moment(
                new Date(
                  formik.values.targetDateTo.getFullYear(),
                  formik.values.targetDateTo.getMonth() + 1,
                  0,
                  23,
                  59,
                  59
                )
              ).toDate()}
              targetdayForm={moment(
                new Date(
                  formik.values.targetDateFrom.getFullYear(),
                  formik.values.targetDateFrom.getMonth(),
                  1
                )
              ).toDate()}
              initOrgValue={String(formik.values.orgCode)}
              staffCode={staffCode}
              orgLabel="組織名"
              addAllItem={true}
              disabled={!isFinished}
            />

            <FormField displayBlock={true} disabled={!isFinished}>
              <SelectForm
                label="雇用形態"
                name="employmentId"
                value={formik.values.employmentId}
                setValue={v => {
                  formik.setFieldValue("employmentId", v);
                }}
                options={employmentOptions}
                readOnly={!isFinished}
              />
            </FormField>

            <FormField displayBlock={true} disabled={!isFinished}>
              <SelectForm
                label="スタッフ"
                name="staffCode"
                value={formik.values.staffCode || ""}
                setValue={v => {
                  // sessionStorage.setItem('selectedStaffCode', v);
                  formik.setFieldValue("staffCode", v);
                }}
                options={staffOptions}
                readOnly={!isFinished}
              />
            </FormField>

            <FormField displayBlock={true} disabled={!isFinished}>
              <FlexBox>
                <div css={styles.label}>
                  <FormLabel label="シフトの再計算" />
                </div>
                <div css={styles.select}>
                  <RadioSelectForm
                    items={arrNecessaryShiftCalc}
                    name="necessaryShiftCalc"
                    value={String(formik.values.necessaryShiftCalc)}
                    setValue={e => {
                      formik.setFieldValue(
                        "necessaryShiftCalc",
                        e.target.value
                      );
                    }}
                    readOnly={!isFinished}
                    noTitle={true}
                  />
                </div>
              </FlexBox>
            </FormField>
            <FormField>
              <FormSubmitArea>
                {isFinished && (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "12px" }}>
                      <Button
                        text="再計算対象確認"
                        onClick={handleCalcTarget}
                      />
                    </div>
                    <div>
                      <Button text="再計算" onClick={handleClickCalc} />
                    </div>
                  </div>
                )}

                {!isFinished && (
                  <Button text="最新表示" onClick={handleReload} />
                )}
              </FormSubmitArea>
            </FormField>
          </form>

          <div
            css={css`
              table th:nth-child(1) {
                width: 100px;
              }
              table th:nth-child(2) {
                width: 100px;
              }
              table th:nth-child(3) {
                width: 100px;
              }
              table th:nth-child(4) {
                width: 100px;
              }
              table th:nth-child(5) {
                width: 100px;
              }
              table th:nth-child(6) {
                width: 100px;
              }
              table th:nth-child(7) {
                width: 100px;
              }
              table th:nth-child(8) {
                width: 100px;
              }
              table th:nth-child(9) {
                width: 100px;
              }
            `}
          >
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <DataTable
                columns={columns}
                data={timeRecalculation}
                isGlobalFilter={true}
                minWidth="972px"
                useSession={true}
                sortBy={[{ id: "targetEndDateStr", desc: false }]}
              />
            </div>
          </div>
        </FormContents>
      </BlockUI>
      <ToastModal
        open={toastModalOpen}
        closeHandler={closeToastModalOpen}
        title={toastMessage}
      />
      <ConfirmModal
        title="確認"
        content={
          <div
            css={css({
              padding: "0 0 16px 0",
              margin: "0",
              fontSize: textFontSize.re,
              borderBottom: `1px solid ${productColor.primaryM90}`
            })}
          >
            <div dangerouslySetInnerHTML={{ __html: confirmModalContent }} />
            <div
              css={css({
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: "8px"
              })}
            >
              <Icon type="warning" color={utilityColor.yellow} size="30px" />
              <BodyText
                color={utilityColor.error}
                size="re"
                customStyle={css({
                  whiteSpace: "pre-wrap"
                })}
              >
                {`再計算を実行すると、本部確定が解除されます。\n本部確定が必要な場合は、再度、本部確定を実行してください。`}
              </BodyText>
            </div>
          </div>
        }
        open={confirmModalOpen}
        closeHandler={closeConfirmModal}
        onSubmit={submitConfirmModal}
        submitText="実行"
        items={[]}
      />
    </div>
  );
};

export default TimeRecalculationForm;
