/** @jsx jsx */
import React, { useCallback } from "react";
import { css, jsx } from "@emotion/core";

import productColor, { textFontSize } from "components/styles";
import Icon from "components/atoms/Icon";
import BodyText from "components/atoms/BodyText";
import Panel from "components/atoms/Panel";

const styles = {
  wrapper: css({
    width: "100%"
  }),
  content: css({
    display: "block",
    width: "100%",
    paddingBottom: "12px"
  }),
  button: css({
    // margin: '6px 8px',
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    outline: "none",
    padding: "15px 0px",
    fontFamily: "inherit",
    text: textFontSize.re,
    minWidth: "140px",
    textAlign: "left"
  }),
  hidden: css({
    display: "none"
  }),
  label: css({
    marginLeft: "4px",
    fontSize: "16px"
  })
};

interface AccordionPanelProps {
  text: string;
  color: string;
  defaultExpand?: boolean;
  callBack: (expand: boolean) => void;
}

const AccordionPanel: React.FC<AccordionPanelProps> = ({
  children,
  text,
  color,
  defaultExpand = false,
  callBack
}) => {
  const [isExpand, setExpand] = React.useState(defaultExpand);

  const onClick = useCallback(() => {
    const v = !isExpand;
    setExpand(v);
    callBack(v);
  }, [callBack, isExpand]);

  return (
    <Panel height="60px">
      <div css={css(styles.wrapper)}>
        <button type="button" css={styles.button} onClick={onClick}>
          {isExpand ? (
            <Icon type="chevronDown" color={color} />
          ) : (
            <Icon type="chevronRight" color={color} />
          )}
          <BodyText customStyle={styles.label}>{text}</BodyText>
        </button>

        <div css={css(styles.content, !isExpand ? styles.hidden : "")}>
          {children}
        </div>
      </div>
    </Panel>
  );
};

export default AccordionPanel;
